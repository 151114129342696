import React from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { useQuitacao } from '../../../QuitacaoContext';
import { Filtros, Parcela } from '../../../protocols';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { FaEraser } from 'react-icons/fa';

const FormActions: React.FC = () => {
  const {
    formFiltros,
    onChangeParcelas,
    handleClearFormFilters,
    disabledFields,
    handleDisabledFields,
    handleCancelFilters,
    handleInitPesquisar,
    onChangeFormFiltros,
    handleInitData,

    formTela: { getValues, clearErrors, setValue, handleSubmit },

    setLoadingTableTitulos,
  } = useQuitacao();

  const getFilters = (): Filtros => {
    const filters: Filtros = {
      cod_loja: formFiltros.cod_loja.value,
      cod_pessoa: formFiltros.cod_pessoa.value.value,
      cod_finalizadora: formFiltros.cod_finalizadora?.value?.value,
      cod_categoria: formFiltros.cod_categoria?.value?.value,
      tipo_conta: formFiltros.tipo_conta.value,
      tipo_periodo: formFiltros.tipo_periodo.value,
      dta_ini: formFiltros.dta_ini?.value,
      dta_fim: formFiltros.dta_fim?.value,
    };
    return filters;
  };

  const clearFields = () => {
    handleInitPesquisar(false);
    clearErrors();
    handleClearFormFilters();
  };

  const onSearch = handleSubmit(async () => {
    try {
      setLoadingTableTitulos(true);
      const { cod_pessoa } = getValues();

      if (cod_pessoa === undefined || cod_pessoa === '') {
        setValue('cod_pessoa', undefined);
        onChangeFormFiltros({
          ...formFiltros,
          cod_pessoa: {
            ...formFiltros.cod_pessoa,
            value: {
              value: undefined,
              label: undefined,
            },
            isInvalid: true,
          },
        });
        const input = await document.getElementById('cod_pessoa');
        input?.focus();
        handleInitPesquisar(true);
        return false;
      }
      const lojas = formFiltros.cod_loja.value;

      if ((Array.isArray(lojas) && lojas.length < 1) || !lojas) {
        return toast.warning('Selecione a(s) loja(s) para pesquisar.');
      }
      if(formFiltros.dta_ini.value === '' && formFiltros.dta_fim.value === ''){
        handleInitData(false);
      }

      if (formFiltros.dta_ini.value && !formFiltros.dta_fim.value) {
        handleInitData(true);
        return onChangeFormFiltros({
          ...formFiltros,
          cod_pessoa: {
            ...formFiltros.cod_pessoa,
            isInvalid: false,
          },
          dta_fim: {
            ...formFiltros.dta_fim,
            isInvalid: true,
          },
        });
      }

      if (formFiltros.dta_fim.value && !formFiltros.dta_ini.value) {
        handleInitData(true);
        return onChangeFormFiltros({
          ...formFiltros,
          cod_pessoa: {
            ...formFiltros.cod_pessoa,
            isInvalid: false,
          },
          dta_ini: {
            ...formFiltros.dta_ini,
            isInvalid: true,
          },
        });
      }

      if (
        formFiltros.dta_ini.value !== undefined &&
        formFiltros.dta_fim.value !== undefined &&
        formFiltros.dta_ini.value > formFiltros.dta_fim.value
      ) {
        return toast.warning(
          'A data inicial deve ser menor ou igual a data final.',
        );
      }

      const filters = getFilters();

      const res = await api.post('/quitacao/parcelas', filters);
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);
      if (data.length < 1) {
        toast.warning(
          'Nenhum título encontrado conforme os critérios informados',
        );
        return;
      }
      const parcelas: Parcela[] = data;
      onChangeParcelas(parcelas);
      handleDisabledFields(true);
    } catch (error) {
      toast.dark((error as Error).message);
    } finally {
      setLoadingTableTitulos(false);
    }
  });

  return (
    <>
      <CustomButtonNovo
        onClick={onSearch}
        disabled={disabledFields}
        label="Pesquisar"
        width="33%"
        icon={IoIosSearch}
        className="button"
      />

      <CustomButtonNovo
        onClick={handleCancelFilters}
        disabled={!disabledFields}
        label="Cancelar"
        variant="cancel"
        width="33%"
        icon={MdOutlineCancel}
        className="button"
      />

      <CustomButtonNovo
        onClick={clearFields}
        disabled={disabledFields}
        label="Limpar"
        variant="clear"
        width="33%"
        icon={FaEraser}
        className="button"
      />
    </>
  );
};

export default FormActions;
