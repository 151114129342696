/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react';

import { Button, Modal } from 'react-bootstrap';

import { BtnContainer, ReceiptModal } from './styles';

import { toast } from 'react-toastify';
import { useQuitacao } from '~/pages/QuitacaoEmLote/QuitacaoContext';
import api from '~/services/api';

import { jsPDF } from 'jspdf';
import { AiOutlinePrinter } from 'react-icons/ai';
import { GoDownload } from 'react-icons/go';
import { FormQuitacao } from '~/pages/QuitacaoEmLote/protocols';
import { ReceiptData } from '~/pages/QuitacaoEmLote/protocols/receipt.data';
import ImpressaoRecibo from '~/utils/classes/ImpressaoRecibo/ImpressaoRecibo';

export const FormActions: React.FC = () => {
  const {
    formQuitacao,
    disabledFields,
    handleInit,
    selectedParcelas,
    handleDisabledFields,
    handleInitPesquisar,
    handleClearFormFilters,
    receiptData,
    setReceiptData,
    formFiltros,
    setLoader,
    showModalRecibo,
    setShowModalRecibo,
    recibo,
    setRecibo,
    formTela,
  } = useQuitacao();

  const { setValue } = formTela;

  const validaInputsParaSubmit = useCallback(
    (inputs: FormQuitacao): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          formQuitacao[Object.keys(inputs)[i] as keyof FormQuitacao]
            .isRequired &&
          formQuitacao[Object.keys(inputs)[i] as keyof FormQuitacao].isInvalid
        ) {
          return false;
        }
      }
      return true;
    },
    [formQuitacao],
  );

  const generateRecibo = useCallback(
    async (data?: ReceiptData, baixar = false) => {
      const impressaoRecibo = new ImpressaoRecibo(
        data || receiptData,
        selectedParcelas,
      );
      const reciboGerado = await impressaoRecibo.baixarRecibo(baixar);
      setRecibo(reciboGerado);
      setShowModalRecibo(true);
    },
    [receiptData, selectedParcelas, setRecibo, setShowModalRecibo],
  );

  const handleQuitarParcelas = useCallback(async () => {
    try {
      setLoader(true);
      if (selectedParcelas.length < 1) {
        return toast.warning('Nenhum título selecionado para quitação');
      }
      handleInit(true);
      if (!validaInputsParaSubmit(formQuitacao)) {
        return;
      }

      const quitacao = {
        cod_cc: formQuitacao.cod_cc.value.value,
        cod_finalizadora: formFiltros.cod_finalizadora.value.value,
        dta_quitada: formQuitacao.dta_quitacao.value,
        parcelas: selectedParcelas,
      };
      const res = await api.post('/quitacao', quitacao);

      const { message } = res.data;

      if (formFiltros.tipo_conta.value === 1) {
        setReceiptData(res.data.data[0]);
        generateRecibo(res.data.data[0], false);
      }

      setTimeout(() => {
        setValue('cod_pessoa', { value: undefined, label: '' });
      }, 300);

      handleDisabledFields(false);
      handleInitPesquisar(false);
      handleClearFormFilters();

      return toast.success(message);
    } finally {
      setLoader(false);
    }
  }, [
    formFiltros.cod_finalizadora.value.value,
    formFiltros.tipo_conta.value,
    formQuitacao,
    generateRecibo,
    handleClearFormFilters,
    handleDisabledFields,
    handleInit,
    handleInitPesquisar,
    selectedParcelas,
    setLoader,
    setReceiptData,
    validaInputsParaSubmit,
    setValue,
  ]);

  const gerarEbaixarPDF = (receipt?: string, imprimir = false) => {
    const receiptInText = receipt || recibo;
    const linhas = receiptInText.split('\n');
    const numeroDeLinhas = linhas.length;

    const espacamentoEntreLinhas = 5; // Ajuste este valor conforme necessário
    const alturaPagina = numeroDeLinhas * espacamentoEntreLinhas + 20; // + 20mm de margem
    const larguraPagina = 80;

    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      // format: [4 * 25.4, 6 * 25.4], // Aproximadamente 4x6 polegadas
      format: [larguraPagina, alturaPagina],
    });

    doc.setFont('Courier'); // Define a fonte para Courier
    doc.setFontSize(8); // Define o tamanho da fonte
    doc.setTextColor(0, 0, 0); // Define a cor do texto para preto

    linhas.forEach((linha, index) => {
      doc.text(linha, 0, 10 + index * espacamentoEntreLinhas);
    });

    if (imprimir) {
      // Obtém o conteúdo do PDF como uma string
      const pdfContent = doc.output();

      // Abre o conteúdo do PDF em uma nova janela ou guia
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        newWindow.document.write(
          '<html><head><title>Recibo</title></head><body style="margin: 0; padding: 0;">',
        );
        newWindow.document.write(
          `<embed width="100%" height="100%" name="plugin" src="data:application/pdf;base64,${btoa(
            pdfContent,
          )}" type="application/pdf">`,
        );
        newWindow.document.write('</body></html>');
      } else {
        toast.error('Não foi possível abrir o recibo para impressão.');
      }
      return;
    }
    doc.save('recibo.pdf');
  };

  return (
    <>
      <BtnContainer>
        {/* <Button variant="secondary">Imprimir</Button> */}
        <Button
          className="btn-quitacao"
          onClick={handleQuitarParcelas}
          disabled={!disabledFields}
        >
          Quitar
        </Button>
      </BtnContainer>
      <ReceiptModal
        show={showModalRecibo}
        onHide={() => {
          setShowModalRecibo(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        // backdrop={!loading || 'static'}
      >
        <Modal.Header>
          <Modal.Title>Visualização do Recibo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="receipt"
            style={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'Courier New, monospace',
              fontSize: '12px',
              lineHeight: '1.6',
              letterSpacing: '0px',
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'black',
            }}
          >
            <div
              style={{
                border: '1px solid grey',
                paddingLeft: '35px',
                paddingTop: '20px',
                paddingBottom: '40px',
                paddingRight: '5px',
              }}
            >
              {recibo}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              gerarEbaixarPDF(recibo, true);
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '3px',
            }}
          >
            Imprimir
            <AiOutlinePrinter size={24} className="btn-icon" />
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              gerarEbaixarPDF(recibo);
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '3px',
            }}
          >
            Baixar
            <GoDownload size={24} className="btn-icon" />
          </Button>
        </Modal.Footer>
      </ReceiptModal>
    </>
  );
};
